import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Container = styled.div`
  max-width: 90%;
  width: 780px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: ${(props) => (props.isDigitalProductPage ? '65px 0px' : '50px 0px')};
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: ${breakpoints.m}px) {
    padding: 40px 0px;
  }
`
export const Text = styled.p``
export const CtaButtonContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    margin-top: 10px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    > button {
      width: 100%;
    }
  }
`
export const Header = styled.h3`
  font-family: 'P22MackinacProBook';
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 0.19px;
  margin: 0px;
  color: ${(props) => (props.blue ? props.theme.blueDark : props.theme.grayDark)};
  @media (max-width: 900px) {
    text-align: center;
    margin: 5px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 25px;
    line-height: 32px;
    letter-spacing: 0.15px;
  }
`
