import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import Analytics from 'src/utils/Analytics'

import {
  FirePressSection,
  FirePressContainer,
  FireHeader,
  FireSecondaryHeader,
  PressContainer,
  PressRow,
  LogoContainer,
  SubHeader,
  Header,
  FlexBox,
  PressLogoContainer,
  MobilePressLogoContainer,
  HideOnMobile,
  HideOnDesktop,
} from './styles'
import { DigitalButton } from '../DigitalForm/styles'

const DigitalComptibility = ({ page, title }) => {
  const { fireLogo, fireGrayLogo, philipsLogo, sonyLogo, tclLogo, androidLogo, appleLogo } =
    useStaticQuery(graphql`
      query DigitalComptibilityQuery {
        fireLogo: file(relativePath: { eq: "digital/logos/fire.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        fireGrayLogo: file(relativePath: { eq: "digital/logos/fire-gray.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        philipsLogo: file(relativePath: { eq: "digital/logos/philips.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        sonyLogo: file(relativePath: { eq: "digital/logos/sony.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        tclLogo: file(relativePath: { eq: "digital/logos/tcl.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        androidLogo: file(relativePath: { eq: "digital/logos/android.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        appleLogo: file(relativePath: { eq: "digital/logos/apple.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
    `)
  const tvLogos = [
    { image: fireLogo, alt: 'Fire TV Logo', id: 'fire' },
    { image: philipsLogo, alt: 'Philips Logo', id: 'philips' },
    { image: sonyLogo, alt: 'Sony Logo', id: 'sony' },
    { image: tclLogo, alt: 'TCL Logo', id: 'tcl' },
  ]
  const tvMobileLogos = [
    { image: fireLogo, alt: 'Fire TV Logo', id: 'fire' },
    { image: philipsLogo, alt: 'Philips Logo', id: 'philips' },
    { image: tclLogo, alt: 'TCL Logo', id: 'tcl' },
    { image: sonyLogo, alt: 'Sony Logo', id: 'sony' },
  ]
  const tabletLogos = [
    { image: androidLogo, alt: 'Android Logo', id: 'android' },
    { image: appleLogo, alt: 'Apple Logo', id: 'apple' },
  ]

  const handleRedirectDigitalDeepLink = async (e) => {
    e.preventDefault()
    Analytics.track(`Added Digital to Cart`)
    window.location.href = 'https://skylightfamily.page.link/digital'
  }

  const showcompatibilityBanner = page === 'digital-fire' || page === 'digital-product'
  const digitalUpsell = page === 'digital-product'
  return (
    <>
      {showcompatibilityBanner ? (
        <FirePressSection digitalUpsell={digitalUpsell}>
          <FirePressContainer>
            <FireHeader>{title}</FireHeader>
            <FlexBox>
              <FireSecondaryHeader>All {digitalUpsell && 'Amazon'}</FireSecondaryHeader>
              <LogoContainer id="fire-gray">
                <GatsbyImage
                  image={fireGrayLogo.childImageSharp.gatsbyImageData}
                  alt="Fire TV Logo"
                />
              </LogoContainer>
              <HideOnMobile>
                <FireSecondaryHeader>
                  devices, including Fire TVs and any TV with a Fire Stick.
                </FireSecondaryHeader>
              </HideOnMobile>
              <HideOnDesktop>
                <FireSecondaryHeader>devices, including Fire</FireSecondaryHeader>
              </HideOnDesktop>
            </FlexBox>
            <HideOnDesktop>
              <FireSecondaryHeader>TVs and any TV with a Fire Stick.</FireSecondaryHeader>
            </HideOnDesktop>
          </FirePressContainer>
        </FirePressSection>
      ) : (
        <PressContainer>
          <Header>{title}</Header>
          <PressRow>
            <SubHeader>TVs:</SubHeader>
            <PressLogoContainer hide>
              {tvLogos.map((logo, i) => (
                <LogoContainer id={logo.id} key={`${i}-tv`}>
                  <GatsbyImage image={logo.image.childImageSharp.gatsbyImageData} alt={logo.alt} />
                </LogoContainer>
              ))}
            </PressLogoContainer>
            <MobilePressLogoContainer>
              {tvMobileLogos.map((logo, i) => (
                <LogoContainer id={logo.id} key={`${i}-tv-mobile`}>
                  <GatsbyImage image={logo.image.childImageSharp.gatsbyImageData} alt={logo.alt} />
                </LogoContainer>
              ))}
            </MobilePressLogoContainer>
          </PressRow>
          <PressRow>
            <SubHeader>Tablets:</SubHeader>
            <PressLogoContainer>
              {tabletLogos.map((logo, i) => (
                <LogoContainer id={logo.id} key={`${i}-tablet`}>
                  <DigitalButton type="button" onClick={handleRedirectDigitalDeepLink}>
                    <GatsbyImage
                      image={logo.image.childImageSharp.gatsbyImageData}
                      alt={logo.alt}
                    />
                  </DigitalButton>
                </LogoContainer>
              ))}
            </PressLogoContainer>
          </PressRow>
        </PressContainer>
      )}
    </>
  )
}
DigitalComptibility.propTypes = {
  title: PropTypes.string,
  page: PropTypes.string,
}

export default DigitalComptibility
