import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Section = styled.div`
  background-color: ${(props) =>
    props.isDigitalProduct ? props.theme.cream : props.theme.blueLight};
`
export const Container = styled.div`
  margin: 0px auto;
  width: 90%;
  display: flex;
  padding: ${(props) => (props.isDigitalProduct ? '60px 0px 80px' : '70px 0px 0px')};
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    width: 50%;
  }
  @media (max-width: ${breakpoints.xl}px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: ${(props) => (props.isDigitalProduct ? '20px 0px 70px' : '70px 0px 0px')};
    > div {
      width: 70%;
    }
  }
  @media (max-width: ${breakpoints.m}px) {
    > div {
      width: 100%;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: ${(props) => (props.isDigitalProduct ? '0px 0px 40px' : '35px 0px 20px')};
  }
`

export const TvImage = styled.div`
  width: 100%;
`
export const IconContainer = styled.div`
  min-width: ${(props) => (props.isDigitalProduct ? '85px' : '65px')};
  .gatsby-image-wrapper picture img {
    object-fit: fill !important;
  }
  @media (max-width: 1400px) {
    align-self: center;
    min-width: ${(props) => (props.isDigitalProduct ? '85px' : '55px')};
  }
  @media (max-width: ${breakpoints.s}px) {
    min-width: 70px;
    max-width: 70px;
  }
`

export const Text = styled.p`
  font-family: 'FilsonProBook';
  font-size: 24px;
  margin: 0px 40px;
  line-height: 30px;
  letter-spacing: 0.19px;
  @media (max-width: 1400px) {
    font-size: ${(props) => (props.isDigitalProduct ? '22px' : '20px')};
    margin: ${(props) => (props.isDigitalProduct ? '0px 20px' : '0px 40px')};
  }
  @media (max-width: ${breakpoints.xl}px) {
    text-align: center;
    margin-top: 20px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 18px;
    line-height: 24px;
    margin: ${(props) => (props.isDigitalProduct ? '20px 0px' : '20px 0px')};
  }
`

export const DigitalCard = styled.div`
  display: flex;
  margin: ${(props) => (props.isDigitalProduct ? '0px 0px 50px' : '0px auto 30px')};
  width: ${(props) => (props.isDigitalProduct ? '100%' : '540px')};
  @media (max-width: 1400px) {
    margin: ${(props) => (props.isDigitalProduct ? '0px 0px 30px' : '0px auto 30px')};
  }
  @media (max-width: ${breakpoints.xl}px) {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: ${(props) => (props.isDigitalProduct ? '0px 0px 20px' : '0px auto 30px')};
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 0px;
  @media (max-width: ${breakpoints.xl}px) {
    margin-top: 70px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin-top: 30px;
  }
`
export const Header = styled.h3`
  font-family: 'P22MackinacProBook';
  font-size: 38px;
  line-height: 53px;
  letter-spacing: 0.19px;
  text-align: center;
  margin: 0px;
  padding-bottom: 20px;
  @media (max-width: 1400px) {
    padding-bottom: 20px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 25px;
    line-height: 40px;
  }
`
