import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Trans } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'
import { Textbox } from '../DigitalFeatures/styles'

import {
  Section,
  Container,
  TvImage,
  IconContainer,
  Text,
  DigitalCard,
  Row,
  Header,
} from './styles'

const DigitalHowItWorks = ({ page }) => {
  const { searchImg, heartImg, mailImg, digital, tapImg, uploadImg, pictureImg, girlWithPuppy } =
    useStaticQuery(graphql`
      query DigitalHowItWorksQuery {
        searchImg: file(relativePath: { eq: "effortlessToUse/search-blue.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        heartImg: file(relativePath: { eq: "effortlessToUse/heart-blue.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        mailImg: file(relativePath: { eq: "effortlessToUse/mail-blue.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        digital: file(relativePath: { eq: "digital-upsell/digital-how-it-works.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        tapImg: file(relativePath: { eq: "calendar-upsell/tap.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        uploadImg: file(relativePath: { eq: "calendar-upsell/upload.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        pictureImg: file(relativePath: { eq: "calendar-upsell/image.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        girlWithPuppy: file(relativePath: { eq: "calendar-upsell/cal-girl-with-puppy.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
    `)

  const HOW_IT_WORKS_CONSTANTS = {
    'skylight-digital': [
      {
        text: `Search for and download the Skylight app on your Fire TV. (Don't know how? We'll walk you through it!)`,
        image: searchImg,
        alt: 'Magnifying glass',
      },
      {
        text: `Follow the instructions on the screen to create your TV's unique Skylight email address.`,
        image: mailImg,
        alt: 'Envelope',
      },
      {
        text: `Email photos or upload them using the Skylight app on your phone. Then watch them appear on your TV instantly!`,
        image: heartImg,
        alt: 'Heart',
      },
    ],
    'calendar-skylight-plus': [
      {
        text: `Email photos to your Calendar’s unique email address or upload them in the app.`,
        image: uploadImg,
        alt: 'Arrow pointing up indicating upload',
      },
      {
        text: `Photos will appear instantly on your photo slideshow screensaver.`,
        image: pictureImg,
        alt: 'Mountain with sun in the sky',
      },
      {
        text: `Simply tap the screen to exit your photo slideshow and view your events.`,
        image: tapImg,
        alt: 'Hand with pointer finger tapping on the screen',
      },
    ],
  }

  const steps = HOW_IT_WORKS_CONSTANTS[page]
  const image = page === 'skylight-digital' ? digital : girlWithPuppy
  const isDigitalProduct = page === 'skylight-digital'
  return (
    <Section isDigitalProduct={isDigitalProduct}>
      <Container isDigitalProduct={isDigitalProduct}>
        <Row>
          <Header>
            <Trans>How It Works</Trans>
          </Header>
          {steps.map((feature, i) => (
            <React.Fragment key={i}>
              <DigitalCard isDigitalProduct={isDigitalProduct}>
                <IconContainer isDigitalProduct={isDigitalProduct}>
                  <GatsbyImage
                    image={feature.image.childImageSharp.gatsbyImageData}
                    alt={feature.alt}
                  />
                </IconContainer>
                <Textbox>
                  <Text isDigitalProduct={isDigitalProduct}>
                    <Trans>{feature.text}</Trans>
                  </Text>
                </Textbox>
              </DigitalCard>
            </React.Fragment>
          ))}
        </Row>
        <TvImage isDigitalProduct={isDigitalProduct}>
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt="Grand Canyon picture displayed on the Skylight app"
          />
        </TvImage>
      </Container>
    </Section>
  )
}

DigitalHowItWorks.propTypes = {
  page: PropTypes.string.isRequired,
}
export default DigitalHowItWorks
