import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Trans } from 'gatsby-plugin-react-i18next'

import Analytics from 'src/utils/Analytics'
import StoreContext from '../../context/StoreContext'
import AddToCartButton from '../AddToCartButton'
import {
  Heading,
  Subheading,
  Container,
  Content,
  Title,
  HeroContainer,
  TextBox,
  Button,
  CtaButtonContainer,
  LogoContainer,
  HeadingContainer,
  TextBoxContent,
} from './styles'

const DigitalProductForm = ({ product, simplifiedProductTitle, title }) => {
  const { heroImage, fireGrayLogo } = useStaticQuery(graphql`
    query DigitalProductFormQuery {
      heroImage: file(relativePath: { eq: "digital-upsell/digital-product-hero.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      fireGrayLogo: file(relativePath: { eq: "digital/logos/fire-gray.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  const productInfo = product.variants[0]

  const { addToCart } = useContext(StoreContext)
  const [submitting, setSubmitting] = useState(false)

  const handleAddToCart = async (variantId, quantity) => {
    if (!submitting) {
      setSubmitting(true)
      Analytics.track(`Added ${simplifiedProductTitle} to Cart`)
      const items = [{ variantId, quantity }]
      const resp = await addToCart(items)
      if (resp && resp.error) {
        setSubmitting(false)
        alert(resp.error)
      } else {
        navigate('/cart/')
      }
    }
  }
  return (
    <Container>
      <Title>
        <Trans>{title}</Trans>
      </Title>
      <Content>
        <HeroContainer>
          <GatsbyImage image={heroImage.childImageSharp.gatsbyImageData} alt="Save with Plus" />
        </HeroContainer>
        <TextBox>
          <TextBoxContent isDigitalProductPage>
            <HeadingContainer isDigitalProductPage>
              <Heading>
                Turn your Amazon{' '}
                <LogoContainer id="fire-gray">
                  <GatsbyImage
                    image={fireGrayLogo.childImageSharp.gatsbyImageData}
                    alt="Fire TV Logo"
                  />
                </LogoContainer>{' '}
                TV into{' '}
              </Heading>
              <Heading>a big-screen Skylight Frame</Heading>
              <Subheading>
                Easily send photos to your TV using the Skylight Digital add-on.{' '}
                <strong>Your first month is free,</strong> then only $9.99/year (that's just
                83¢/month!). Cancel anytime.
              </Subheading>
            </HeadingContainer>
            <CtaButtonContainer>
              <AddToCartButton
                addingLabel="Adding..."
                widerButton
                adding={submitting}
                handleClick={() => handleAddToCart(productInfo.shopifyId, 1)}
              />
              <Button className="checkout" onClick={() => navigate('/cart/')}>
                No thanks, take me to checkout
              </Button>
            </CtaButtonContainer>
          </TextBoxContent>
        </TextBox>
      </Content>
    </Container>
  )
}

DigitalProductForm.propTypes = {
  product: PropTypes.object.isRequired,
  simplifiedProductTitle: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default DigitalProductForm
