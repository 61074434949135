import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import Analytics from 'src/utils/Analytics'
import { StaticImage } from 'gatsby-plugin-image'
import { FeatureContainer, DiscountDisclaimer } from '../../FramePlusFeatures/styles'
import { Container, Header, GridContainer, Column } from '../CalPlusHero/styles'
import StoreContext from '../../../context/StoreContext'
import { SecondaryHeader, CalPlusImage } from './styles'
import { CalPlusAddToCartButton } from '../CalPlusAddToCartButton'
import { Row } from '../../Matrix/styles'

const CalPlusHero = ({ product }) => {
  const { addToCart } = useContext(StoreContext)
  const [submitting, setSubmitting] = useState(false)

  const handleAddToCart = async (variantId, quantity) => {
    if (!submitting) {
      setSubmitting(true)
      Analytics.track(`Added Calendar Plus to Cart`)
      const items = [{ variantId, quantity }]
      const resp = await addToCart(items)
      if (resp && resp.error) {
        setSubmitting(false)
        alert(resp.error)
      } else {
        navigate('/cart/')
      }
    }
  }

  return (
    <FeatureContainer color="#dcf1f3">
      <Container>
        <Header center>Calendar Plus benefits include:</Header>
        <GridContainer reverse>
          <Column>
            <div>
              <SecondaryHeader>Meal Planning</SecondaryHeader>
              <p>
                Never answer “What are we eating?” again. Plan the whole week’s meals in 5 minutes.
              </p>
            </div>
          </Column>
          <Column>
            <CalPlusImage>
              <StaticImage
                src="../../../images/meal-planning.webp"
                alt="Meals planned on a skylight calendar for the week"
                layout="fullWidth"
                placeholder="none"
              />
            </CalPlusImage>
          </Column>
        </GridContainer>
        <GridContainer>
          <Column>
            <CalPlusImage>
              <StaticImage
                src="../../../images/cal-plus.jpeg"
                alt="Skylight Calendar week view"
                layout="fullWidth"
                placeholder="none"
              />
            </CalPlusImage>
          </Column>
          <Column>
            <div>
              <SecondaryHeader>Magic Import</SecondaryHeader>
              <p>
                Add important school dates to your calendar in one step with Magic Import. Simply
                forward any email or PDF containing dates and we’ll convert into events on your
                Calendar.
              </p>
            </div>
          </Column>
        </GridContainer>
        <GridContainer reverse>
          <Column>
            <div>
              <SecondaryHeader>Photo & Video Screensaver</SecondaryHeader>
              <p>
                Enhance your Calendar’s functionality by converting it into a digital photo frame
                when it’s not in use. Upload photos and videos and add captions using the Skylight
                app.
              </p>
            </div>
          </Column>
          <Column>
            <CalPlusImage>
              <StaticImage
                src="../../../images/cal-plus-screensaver.webp"
                alt="Photo Slideshow feature on Skylight Calendar with an screensaver of a girl holding a puppy"
                layout="fullWidth"
                placeholder="none"
              />
            </CalPlusImage>
          </Column>
        </GridContainer>

        <Column>
          <CalPlusAddToCartButton
            product={product}
            adding={submitting}
            onClick={() => handleAddToCart(product.variants[0].shopifyId, 1)}
          />
        </Column>

        <DiscountDisclaimer>
          Individual Calendar Plus subscriptions are excluded from marketing promotions and
          discounts.
        </DiscountDisclaimer>
      </Container>
    </FeatureContainer>
  )
}

CalPlusHero.propTypes = {
  product: PropTypes.object.isRequired,
}

export default CalPlusHero
