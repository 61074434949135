import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { Header } from '../FrameProductForm/styles'

export const Container = styled.div`
  width: 90%;
  margin: auto;
  padding: 0px 0px 80px;
  @media (max-width: ${breakpoints.xl}px) {
    padding: 0px 0px 50px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 0px;
    margin-bottom: 50px;
  }
`
export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: 48%;
  }
  @media (max-width: ${breakpoints.xl}px) {
    flex-direction: column;
    align-items: center;
    > div {
      width: 70%;
    }
  }
  @media (max-width: ${breakpoints.m}px) {
    > div {
      width: 100%;
    }
  }
`

export const CtaButtonContainer = styled.div`
  @media (max-width: ${breakpoints.l}px) {
    width: 70%;
    margin: auto;
    > button {
      width: 100%;
    }
  }

  @media (max-width: ${breakpoints.m}px) {
    width: 70%;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
  @media (max-width: 350px) {
    .checkout {
      text-indent: -9999px;
      line-height: 0;
    }
    .checkout:after {
      content: 'No thanks, checkout';
      text-indent: 0;
      display: block;
      line-height: initial;
    }
  }
`

export const Heading = styled.h3`
  font-family: 'FilsonProBook';
  font-size: 36px;
  letter-spacing: 0.19px;
  line-height: 46px;
  display: inline;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 15px 0px;
  }
`

export const Subheading = styled.p`
  margin: 20px 0px;
  font-size: 18px;
  strong {
    font-family: 'FilsonProBold';
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 14px;
    line-height: 18px;
    margin: 15px 0px;
  }
`

export const HeroContainer = styled.div`
  width: 50%;
  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
  }
`
export const TextBox = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  @media (max-width: ${breakpoints.xl}px) {
    text-align: center;
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
  }
`

export const Button = styled.button`
  font-family: 'FilsonPro';
  border-width: 1px;
  border-style: 1px;
  border-color: ${(props) => props.theme.coral};
  border-style: solid;
  color: ${(props) => props.theme.coral};
  background-color: transparent;
  border-radius: 28px;
  font-size: 18px;
  font-weight: normal;
  padding: 15px 30px;
  line-height: 1.28;
  letter-spacing: 0.09px;
  margin: 0px 0px 0px 15px;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.cream};
    background-color: ${(props) => props.theme.coral};
    opacity: 0.9;
  }
  &:focus {
    color: ${(props) => props.theme.cream};
    box-shadow: ${(props) => props.theme.coral};
    outline: none;
  }
  @media (max-width: 1280px) {
    font-size: 15px;
  }
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 18px;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin: 15px 0px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    cursor: pointer;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.08px;
  }
`

export const Title = styled(Header)`
  text-align: center;
  width: 100%;
  margin: 45px 0px;
  @media (max-width: ${breakpoints.l}px) {
    margin: 20px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 15px 0px 15px;
    font-size: 25px;
    line-height: 32px;
    letter-spacing: 0.15px;
    padding: 0px 0px;
  }
  @media (max-width: 340px) {
    padding: 0px;
  }
`
export const LogoContainer = styled.span`
  max-width: 42px;
  width: 42px;
  display: inline-block;
  position: relative;
  top: 10px;
  @media (max-width: ${breakpoints.s}px) {
    width: 27px;
  }
`

export const HeadingContainer = styled.div`
  width: ${(props) => (props.isDigitalProductPage ? '500px' : '600px')};
  @media (max-width: 1350px) {
    width: 100%;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-top: 15px;
  }
`
export const TextBoxContent = styled.div`
  @media (max-width: 1330px) {
    margin: auto;
    padding-top: ${(props) => (props.isDigitalProductPage ? '40px' : '0px')};
  }
  @media (max-width: ${breakpoints.xl}px) {
    padding-top: 40px;
  }
  @media (max-width: ${breakpoints.l}px) {
    max-width: 100%;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-top: 0px;
  }
`
