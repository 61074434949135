import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withTranslation } from 'gatsby-plugin-react-i18next'

import SEO from 'src/components/seo'
import Analytics from 'src/utils/Analytics'
import DigitalComptibility from '../../components/DigitalComptibility'
import PerfectGift from '../../components/PerfectGift'
import DigitalProductForm from '../../components/DigitalProductForm'
import DigitalHowItWorks from '../../components/DigitalHowItWorks'
import TryFreeDigital from '../../components/TryFreeDigital'
import CalPlusHero from '../../components/CalPlus/CalPlusHero'
import CalPlusFeatures from '../../components/CalPlus/CalPlusFeatures'

class UpsellProductPage extends React.Component {
  componentDidMount() {
    const {
      data: { digitalProduct },
    } = this.props
    Analytics.track(
      `Viewed ${
        Analytics.trackingConstants[digitalProduct.handle] || digitalProduct.handle
      } Product Page`
    )
  }

  render() {
    const { data, t } = this.props
    const product = data.digitalProduct
    const upsellConstants = {
      seoTitle: {
        'calendar-skylight-plus':
          'Digital Calendar with Photo Display | Skylight Calendar Photo Plus',
      },
      seoDescription: {
        'skylight-digital':
          'Skylight Digital is a $9.99/year subscription that turns your Fire TV into a big-screen photo frame you can email photos to. You can download our app on your TV’s App Store, and 60 seconds later you’ll have photos on display. It’s that easy!',
        'calendar-skylight-plus':
          'Turn your Calendar into a photo frame and seamlessly pull in events from emails or PDFs with Magic Import.',
      },
    }
    const isDigitalProductPage = product.handle === 'skylight-digital'
    return (
      <>
        <SEO
          title={t(upsellConstants.seoTitle[product.handle]) || product.title}
          description={t(upsellConstants.seoDescription[product.handle])}
          path={`/products/${product.handle}/`}
          imagePath={product.images[0].gatsbyImageData.images.fallback.src}
        />
        {isDigitalProductPage ? (
          <>
            <DigitalProductForm
              product={product}
              title="Customize Your Skylight Experience"
              handle={product.handle}
              simplifiedProductTitle="Digital"
            />
            <DigitalComptibility
              title={t('SKYLIGHT DIGITAL IS COMPATIBLE WITH:')}
              page="digital-product"
            />
            <DigitalHowItWorks page={product.handle} />
            <PerfectGift
              page="digital"
              title="Turn your TV into a larger version of your Skylight Frame."
              bgColor="#f1f0fb"
              titleColor="#444444"
            />
            <TryFreeDigital product={product} />
          </>
        ) : (
          <>
            <CalPlusHero product={product} />
            <CalPlusFeatures product={product} />
          </>
        )}
      </>
    )
  }
}

UpsellProductPage.propTypes = {
  data: PropTypes.object.isRequired,
  t: PropTypes.func,
}

export const query = graphql`
  query ($handle: String!, $language: String!) {
    digitalProduct: shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      variants {
        id
        title
        price
        shopifyId
      }
      images {
        id
        gatsbyImageData
      }
    }
    locales: allLocale(filter: { ns: { in: ["common", "upsell"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default withTranslation()(UpsellProductPage)
