import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { Text } from '../DigitalSteps/styles'

export const FirePressSection = styled.div`
  background-color: ${(props) => (props.digitalUpsell ? props.theme.blueLight : props.theme.lilac)};
  margin: 30px 0px 40px;
  @media (max-width: ${breakpoints.xl}px) {
    margin: 50px 0px 60px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: ${(props) => (props.digitalUpsell ? '50px 0px 40px' : '70px 0px 60px')};
  }
`
export const FirePressContainer = styled.div`
  max-width: 950px;
  padding: 30px 0px;
  margin: auto;
  @media (max-width: ${breakpoints.l}px) {
    max-width: 90%;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 30px 0px 30px;
  }
`
export const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > #fire-gray {
    width: 38px;
    margin: 0px 0px 0px 8px;
    padding-top: 10px;
  }
  > p {
    margin: 0px;
  }
  @media (max-width: ${breakpoints.m}px) {
    align-items: baseline;
    flex-wrap: wrap;
    > #fire-gray {
      width: 30px;
      margin: 0px 5px;
      position: relative;
      top: 10px;
    }
  }
`
export const PressContainer = styled.div`
  background-color: ${(props) => props.theme.cream};
  max-width: 950px;
  padding: 80px 0 60px;
  margin: auto;
  @media (max-width: ${breakpoints.l}px) {
    max-width: 650px;
  }
  @media (max-width: ${breakpoints.m}px) {
    max-width: 500px;
  }
  @media (max-width: ${breakpoints.s}px) {
    max-width: 400px;
    padding: 80px 0 20px;
  }
`
export const PressLogoContainer = styled.div`
  display: flex;
  align-items: center;
  > #fire {
    width: 75px;
  }
  > #philips {
    width: 175px;
  }
  > #sony {
    width: 180px;
  }
  > #tcl {
    width: 110px;
  }
  > #apple {
    width: 56px;
  }
  > #android {
    min-width: 62px;
  }
  @media (max-width: ${breakpoints.l}px) {
    flex-wrap: wrap;
    justify-content: center;
    max-width: 500px;
  }
  @media (max-width: ${breakpoints.m}px) {
    display: ${(props) => (props.hide ? 'none' : 'flex')};
    max-width: 350px;
    > #fire {
      width: 55px;
    }
    > #philips {
      width: 130px;
    }
    > #sony {
      width: 130px;
    }
    > #tcl {
      width: 85px;
    }
    > #apple {
      width: 45px;
    }
    > #android {
      min-width: 48px;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    max-width: 300px;
    > #fire {
      width: 40px;
    }
    > #philips {
      width: 90px;
    }
    > #sony {
      width: 80px;
    }
    > #tcl {
      width: 60px;
    }
    > #apple {
      width: 32px;
    }
    > #android {
      min-width: 37px;
    }
  }
`
export const MobilePressLogoContainer = styled(PressLogoContainer)`
  display: none;
  @media (max-width: ${breakpoints.m}px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const PressRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px 0px;
  @media (max-width: ${breakpoints.l}px) {
    margin: 10px 0px;
  }
`

export const LogoContainer = styled.div`
  margin: 0px 20px;
  @media (max-width: ${breakpoints.l}px) {
    margin: 15px 20px;
  }
`

export const Header = styled.p`
  text-align: center;
  font-family: 'FilsonProBook';
  font-size: 18px;
  line-height: 26px;
  @media (max-width: ${breakpoints.m}px) {
    margin: 15px auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    line-height: 26px;
    margin: 10px auto;
    max-width: 90%;
  }
`
export const FireHeader = styled(Header)`
  margin: 8px;
  font-size: 22px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    line-height: 26px;
    margin: auto;
    max-width: 100%;
  }
  @media (max-width: 375px) {
    font-size: 14px;
  }
`
export const FireSecondaryHeader = styled(Text)`
  margin: 8px;
  @media (max-width: ${breakpoints.l}px) {
    width: auto;
    font-size: 20px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 18px;
    text-align: center;
    margin: 0px;
  }
  @media (max-width: ${breakpoints.m}px) {
    margin: 2px 0px;
  }
`

export const SubHeader = styled.p`
  font-size: 18px;
  line-height: 26px;
  @media (max-width: ${breakpoints.l}px) {
    min-width: 100%;
    text-align: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    line-height: 26px;
    margin: 10px 0px;
  }
`
export const HideOnDesktop = styled.div`
  display: none;
  @media (max-width: ${breakpoints.m}px) {
    display: block;
  }
`
export const HideOnMobile = styled.div`
  display: block;
  @media (max-width: ${breakpoints.m}px) {
    display: none;
  }
`
