import styled from 'styled-components'
import { darken, lighten } from 'polished'
import { breakpoints } from 'src/utils/styles/global-styles'

export const AddToCart = styled.button`
  font-family: 'FilsonPro';
  background-color: ${(props) =>
    props.oos ? '#F3C6BC' : props.backgroundColor ? props.backgroundColor : props.theme.coral};
  color: ${(props) => (props.textColor ? props.textColor : 'white')};
  text-decoration: none;
  border-radius: 28px;
  border: none;
  font-size: 18px;
  font-weight: normal;
  padding: ${(props) => (props.widerButton ? '15px 50px' : '15px 30px')};
  line-height: 1.28;
  letter-spacing: 0.09px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.oos
        ? '#F3C6BC'
        : props.backgroundColor
        ? darken(0.1, props.backgroundColor)
        : darken(0.1, props.theme.coral)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.theme.coral)}`};
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.08px;
  }
`
