import styled from 'styled-components'
import { breakpoints, TwoColumnGrid, GridRight } from 'src/utils/styles/global-styles'

export const Container = styled.div`
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: ${breakpoints.xl}px) {
    flex-direction: column-reverse;
    margin: 0px auto 50px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 35px auto 20px;
  }
`

export const AlbumsContainer = styled.div`
  max-width: 90%;
  margin: 45px auto;
`
export const Grid = styled(TwoColumnGrid)`
  align-items: center;
`
export const Row = styled.div`
  margin: 70px 30px;
  @media (max-width: ${breakpoints.xl}px) {
    margin: 40px auto 0px;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin: 0px 0px;
  }
`
export const Card = styled.div`
  display: flex;
  margin: 20px 0px;
  @media (max-width: ${breakpoints.xl}px) {
    margin: 16px 0px;
  }
  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
    margin: 32px 0px;
  }
`

export const Text = styled.p`
  font-family: 'FilsonProLight';
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.09px;
  color: ${(props) => props.theme.grayDark};
  width: 430px;
  text-align: left;
  margin: 8px 0px;
  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
    margin: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    width: 90%;
    margin: 8px auto;
  }
`
export const ImageContainer = styled.div`
  width: 75px;
  margin: 10px 30px;
  @media (max-width: ${breakpoints.l}px) {
    margin: 10px auto;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 70px;
  }
`
export const AlbumImageContainer = styled.div`
  width: 450px;
  @media (max-width: ${breakpoints.l}px) {
    margin: auto;
    max-width: 100%;
  }
`
export const GrandCanyonImageContainer = styled.div`
  width: 520px;
  margin-top: 60px;
  margin-bottom: 40px;
  @media (max-width: ${breakpoints.xl}px) {
    margin: auto;
    margin-top: 40px;
  }
  @media (max-width: ${breakpoints.l}px) {
    max-width: 90%;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin-top: 0px;
  }
`

export const Textbox = styled.div`
  display: block;
`
export const Title = styled(Text)`
  min-width: 100%;
  font-family: 'FilsonProMedium';
  font-size: 22px;
  letter-spacing: 0.15px;
`
export const MediumHeader = styled.h2`
  width: 100%;
  text-align: center;
  font-family: 'P22MackinacProMedium';
  color: ${(props) => (props.blue ? '#2178af' : '#444444')};
  font-size: 34px;
  line-height: 50px;
  margin: 0px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 32px;
    line-height: 47px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.15px;
  }
`

export const FeaturesHeader = styled(MediumHeader)`
  padding-top: 55px;
  @media (max-width: ${breakpoints.xl}px) {
    color: ${(props) => props.theme.blueDark};
    padding-top: 35px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-top: 5px;
  }
`

export const RightGrid = styled(GridRight)`
  display: ${(props) => (props.hideOnMobile ? 'block' : 'none')};
  @media (max-width: ${breakpoints.m}px) {
    display: ${(props) => (props.hideOnMobile ? 'none' : 'block')};
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.15px;
    > h2 {
      margin-top: 30px;
    }
  }
`
export const Description = styled(Text)`
  text-align: center;
  width: 100%;
  margin: 10px auto;
  @media (max-width: ${breakpoints.s}px) {
    width: 270px;
    max-width: 100%;
  }
`
export const MemoriesRow = styled.div`
  background-color: ${(props) => props.theme.blueLight};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 54px 0px;
  margin: 60px 0px 100px;
  > h2 {
    width: auto;
    margin-right: 45px;
  }
  @media (max-width: ${breakpoints.s}px) {
    display: block;
    text-align: center;
    padding: 10px 0px 60px;
    margin: 20px 0px 60px;
    > h2 {
      margin: 30px 30px;
      max-width: 90%;
    }
  }
`
