import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Analytics from 'src/utils/Analytics'
import { navigate } from 'gatsby'
import AddToCartButton from '../AddToCartButton'

import StoreContext from '../../context/StoreContext'
import { Container, CtaButtonContainer, Header } from './styles'

const TryFreeDigital = ({ product, productName }) => {
  const { addToCart } = useContext(StoreContext)
  const [submitting, setSubmitting] = useState(false)

  const handleAddToCart = async (variantId, quantity) => {
    if (!submitting) {
      setSubmitting(true)
      Analytics.track(`Added Digital to Cart`)
      const items = [{ variantId, quantity }]
      const resp = await addToCart(items)
      if (resp && resp.error) {
        setSubmitting(false)
        alert(resp.error)
      } else {
        navigate('/cart/')
      }
    }
  }
  const productInfo = product.variants[0]
  const isDigitalProductPage = productName === 'Skylight Digital'
  return (
    <Container isDigitalProductPage={isDigitalProductPage}>
      <div>
        <Header>Try {productName} for one month free!</Header>
        <Header blue>If you don't like it, you can cancel at anytime.</Header>
      </div>
      <CtaButtonContainer>
        <AddToCartButton
          widerButton
          addingLabel="Adding"
          adding={submitting}
          handleClick={() => handleAddToCart(productInfo.shopifyId, 1)}
        />
      </CtaButtonContainer>
    </Container>
  )
}
TryFreeDigital.propTypes = {
  product: PropTypes.object.isRequired,
  productName: PropTypes.string.isRequired,
}
export default TryFreeDigital
